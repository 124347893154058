import React, { useEffect } from 'react';
import SimpleLightbox from 'simplelightbox';
import 'simplelightbox/dist/simple-lightbox.min.css';

function Home() {
  useEffect(() => {
    // Activate SimpleLightbox plugin for portfolio items
    const lightbox = new SimpleLightbox('.portfolio-box');

    // Ensure that SimpleLightbox is properly destroyed when the component unmounts
    return () => {
      lightbox.destroy();
    };
  }, []);

  return (
    <>
      <div>
        {/* About*/}
        <section className="page-section bg-primary" id="about">
          <div className="container px-4 px-lg-5">
            <div className="row gx-4 gx-lg-5 justify-content-center">
              <div className="col-lg-8 text-center">
                <h2 className="text-white mt-0">We've got what you need!</h2>
                <hr className="divider divider-light" />
                <p className="text-white-75 mb-4">We prioritize client satisfaction above all else, offering personalized service tailored to meet individual preferences and surpass expectations, making us the ideal choice for any construction project.</p>
                <a className="btn btn-light btn-xl" href="#contact">Get Started!</a>
              </div>
            </div>
          </div>
        </section>
        {/* Services*/}
        <section className="page-section" id="services">
          <div className="container px-4 px-lg-5">
            <h2 className="text-center mt-0">At Your Service</h2>
            <hr className="divider" />
            <div className="row gx-4 gx-lg-5">
              <div className="col-lg-3 col-md-6 text-center">
                <div className="mt-5">
                  <div className="mb-2"><i className="bi-gem fs-1 text-primary" /></div>
                  <h3 className="h4 mb-2">Experienced</h3>
                  <p className="text-muted mb-0">Vast experience and deep industry knowledge ensure precise project execution.</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 text-center">
                <div className="mt-5">
                  <div className="mb-2"><i className="bi-laptop fs-1 text-primary" /></div>
                  <h3 className="h4 mb-2">Skilled</h3>
                  <p className="text-muted mb-0">We leverage advanced methods for efficient and timely completion.</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 text-center">
                <div className="mt-5">
                  <div className="mb-2"><i className="bi-globe fs-1 text-primary" /></div>
                  <h3 className="h4 mb-2">Eco-friendly</h3>
                  <p className="text-muted mb-0">Commitment to sustainability means eco-friendly and energy-efficient results.</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 text-center">
                <div className="mt-5">
                  <div className="mb-2"><i className="bi-heart fs-1 text-primary" /></div>
                  <h3 className="h4 mb-2">Made with Love</h3>
                  <p className="text-muted mb-0">Clients value our craftsmanship, transparent communication, and personalized service, leading to trust and satisfaction.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Portfolio*/}
        <div id="portfolio">
          <div className="container-fluid p-0">
            <div className="row g-0">
              <div className="col-lg-4 col-sm-6">
                <a className="portfolio-box" href="assets/img/portfolio/fullsize/1.jpg" title="Project Name">
                  <img className="img-fluid" src="assets/img/portfolio/thumbnails/1.jpg" alt="..." />
                  <div className="portfolio-box-caption">
                    <div className="project-category text-white-50">Painting</div>
                    <div className="project-name">Exterior water proofing and painting</div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-sm-6">
                <a className="portfolio-box" href="assets/img/portfolio/fullsize/2.jpg" title="Project Name">
                  <img className="img-fluid" src="assets/img/portfolio/thumbnails/2.jpg" alt="..." />
                  <div className="portfolio-box-caption">
                    <div className="project-category text-white-50">Christmas lights</div>
                    <div className="project-name">Christmas lights setup</div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-sm-6">
                <a className="portfolio-box" href="assets/img/portfolio/fullsize/3.jpg" title="Project Name">
                  <img className="img-fluid" src="assets/img/portfolio/thumbnails/3.jpg" alt="..." />
                  <div className="portfolio-box-caption">
                    <div className="project-category text-white-50">Remodel</div>
                    <div className="project-name">Kitchen remodel</div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-sm-6">
                <a className="portfolio-box" href="assets/img/portfolio/fullsize/4.jpg" title="Project Name">
                  <img className="img-fluid" src="assets/img/portfolio/thumbnails/4.jpg" alt="..." />
                  <div className="portfolio-box-caption">
                    <div className="project-category text-white-50">Drywall</div>
                    <div className="project-name">Drywall installation</div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-sm-6">
                <a className="portfolio-box" href="assets/img/portfolio/fullsize/5.jpg" title="Project Name">
                  <img className="img-fluid" src="assets/img/portfolio/thumbnails/5.jpg" alt="..." />
                  <div className="portfolio-box-caption">
                    <div className="project-category text-white-50">Remodel</div>
                    <div className="project-name">Kitchen & bathroom remodel</div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-sm-6">
                <a className="portfolio-box" href="assets/img/portfolio/fullsize/6.jpg" title="Project Name">
                  <img className="img-fluid" src="assets/img/portfolio/thumbnails/6.jpg" alt="..." />
                  <div className="portfolio-box-caption">
                    <div className="project-category text-white-50">Remodel</div>
                    <div className="project-name">Kitchen & bathroom remodel</div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-sm-6">
                <a className="portfolio-box" href="assets/img/portfolio/fullsize/cabinetRemodel.jpeg" title="Project Name">
                  <img className="img-fluid" src="assets/img/portfolio/thumbnails/cabinetRemodel.jpeg" alt="..." />
                  <div className="portfolio-box-caption">
                    <div className="project-category text-white-50">Remodel</div>
                    <div className="project-name">Kitchen & bathroom remodel</div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-sm-6">
                <a className="portfolio-box" href="assets/img/portfolio/fullsize/cabinetRemodelTwo.jpeg" title="Project Name">
                  <img className="img-fluid" src="assets/img/portfolio/thumbnails/cabinetRemodelTwo.jpeg" alt="..." />
                  <div className="portfolio-box-caption">
                    <div className="project-category text-white-50">Remodel</div>
                    <div className="project-name">Kitchen & bathroom remodel</div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-sm-6">
                <a className="portfolio-box" href="assets/img/portfolio/fullsize/cabinetRemodelThree.jpeg" title="Project Name">
                  <img className="img-fluid" src="assets/img/portfolio/thumbnails/cabinetRemodelThree.jpeg" alt="..." />
                  <div className="portfolio-box-caption p-3">
                    <div className="project-category text-white-50">Drywall</div>
                    <div className="project-name">Drywall installation</div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Call to action*/}
        <section className="page-section bg-dark text-white">
          <div className="container px-4 px-lg-5 text-center">
            <h2 className="mb-4">Check us out on Nextdoor!</h2>
            <a className="btn btn-light btn-xl" href="https://nextdoor.com/profile/01fwRYP7hDKM5DCgC">Take me to Nextdoor!</a>
          </div>
        </section>
        {/* Contact*/}
        <section className="page-section" id="contact">
          <div className="container px-4 px-lg-5">
            <div className="row gx-4 gx-lg-5 justify-content-center">
              <div className="col-lg-8 col-xl-6 text-center">
                <h2 className="mt-0">Let's Get In Touch!</h2>
                <hr className="divider" />
                <p className="text-muted mb-5">Ready to start your next project with us? Send us a messages and we will get back to you as soon as possible!</p>
              </div>
            </div>
            <div className="row gx-4 gx-lg-5 justify-content-center mb-5">
              <div className="col-lg-6">
                {/* * * * * * * * * * * * * * * **/}
                {/* * * SB Forms Contact Form * **/}
                {/* * * * * * * * * * * * * * * **/}
                {/* This form is pre-integrated with SB Forms.*/}
                {/* To make this form functional, sign up at*/}
                {/* https://startbootstrap.com/solution/contact-forms*/}
                {/* to get an API token!*/}
                <form id="contactForm" data-sb-form-api-token="API_TOKEN">
                  {/* Name input*/}
                  <div className="form-floating mb-3">
                    <input className="form-control" id="name" type="text" placeholder="Enter your name..." data-sb-validations="required" />
                    <label htmlFor="name">Full name</label>
                    <div className="invalid-feedback" data-sb-feedback="name:required">A name is required.</div>
                  </div>
                  {/* Email address input*/}
                  <div className="form-floating mb-3">
                    <input className="form-control" id="email" type="email" placeholder="name@example.com" data-sb-validations="required,email" />
                    <label htmlFor="email">Email address</label>
                    <div className="invalid-feedback" data-sb-feedback="email:required">An email is required.</div>
                    <div className="invalid-feedback" data-sb-feedback="email:email">Email is not valid.</div>
                  </div>
                  {/* Phone number input*/}
                  <div className="form-floating mb-3">
                    <input className="form-control" id="phone" type="tel" placeholder="(123) 456-7890" data-sb-validations="required" />
                    <label htmlFor="phone">Phone number</label>
                    <div className="invalid-feedback" data-sb-feedback="phone:required">A phone number is required.</div>
                  </div>
                  {/* Message input*/}
                  <div className="form-floating mb-3">
                    <textarea className="form-control" id="message" type="text" placeholder="Enter your message here..." style={{ height: '10rem' }} data-sb-validations="required" defaultValue={""} />
                    <label htmlFor="message">Message</label>
                    <div className="invalid-feedback" data-sb-feedback="message:required">A message is required.</div>
                  </div>
                  {/* Submit success message*/}
                  {/**/}
                  {/* This is what your users will see when the form*/}
                  {/* has successfully submitted*/}
                  <div className="d-none" id="submitSuccessMessage">
                    <div className="text-center mb-3">
                      <div className="fw-bolder">Form submission successful!</div>
                      To activate this form, sign up at
                      <br />
                      <a href="https://startbootstrap.com/solution/contact-forms">https://startbootstrap.com/solution/contact-forms</a>
                    </div>
                  </div>
                  {/* Submit error message*/}
                  {/**/}
                  {/* This is what your users will see when there is*/}
                  {/* an error submitting the form*/}
                  <div className="d-none" id="submitErrorMessage"><div className="text-center text-danger mb-3">Error sending message!</div></div>
                  {/* Submit Button*/}
                  <div className="d-grid"><button className="btn btn-primary btn-xl disabled" id="submitButton" type="submit">Submit</button></div>
                </form>
              </div>
            </div>
            <div className="row gx-4 gx-lg-5 justify-content-center">
              <div className="col-lg-4 text-center mb-5 mb-lg-0">
                <i className="bi-phone fs-2 mb-3 text-muted" />
                <div>+1 (206) 945-1830</div>
              </div>
            </div>
          </div>
        </section>
      </div>

    </>
  );
}

export default Home;
