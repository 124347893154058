import React from 'react'

function Footer() {
    return (
        <>{/* Footer*/}
<footer className="bg-light py-5">
  <div className="container px-4 px-lg-5"><div className="small text-center text-muted">Copyright © 2023 - VisionaryGCLLC</div></div>
</footer>

        </>
    );
}

export default Footer;
