import Header from './Header'
import Home from './Home'
import Footer from './Footer'

function App() {
  return (
    <>
   <Header/>
   <Home/>
   <Footer/>
   </>
  );
}

export default App;
